/*
	Tactile by Pixelarity
	pixelarity.com | hello@pixelarity.com
	License: pixelarity.com/license
*/

/* Header */

	body {
		padding-top: 3.125em;
	}

	#header {
		background: _palette(bg);
		border-bottom: solid 1px _palette(border);
		color: _palette(fg);
		height: 3.25em;
		left: 0;
		line-height: 3.25em;
		position: fixed;
		text-align: right;
		text-transform: uppercase;
		top: 0;
		width: 100%;
		z-index: _misc(z-index-base) + 1;

		> h1 {
			display: inline-block;
			font-weight: _font(weight);
			height: inherit;
			left: 1.25em;
			line-height: inherit;
			margin: 0;
			padding: 0;
			position: absolute;
			top: 0;

			a {
				font-weight: _font(weight-bold);
			}

			span {
				font-weight: _font(weight);
			}
		}

		> a {
			@include vendor('transition', 'color #{_duration(transition)} ease-in-out');
			display: inline-block;
			padding: 0 0.75em;
			color: inherit;
			text-decoration: none;

			&:hover {
				color: _palette(fg-bold);
			}

			&[href="#menu"] {
				@include icon;
				font-weight: _font(weight-bold);
				-webkit-tap-highlight-color: rgba(0,0,0,0);

				&:before {
					content: '\f0c9';
					color: _palette(border2);
					margin: 0 0.5em 0 0;
				}
			}

			& + a[href="#menu"]:last-child {
				border-left: solid 1px _palette(border);
				padding-left: 1.25em;
				margin-left: 0.5em;
			}

			&:last-child {
				padding-right: 1.25em;
			}

			@include breakpoint(small) {
				padding: 0 0.5em;

				& + a[href="#menu"]:last-child {
					padding-left: 1em;
					margin-left: 0.25em;
				}

				&:last-child {
					padding-right: 1em;
				}
			}
		}
	}

	@include breakpoint(medium) {
		body {
			padding-top: 44px;
		}

		#header {
			height: 44px;
			line-height: 44px;

			> h1 {
				left: 1em;

				a {
					font-size: 1em;
				}
			}
		}
	}

	@include breakpoint(xsmall) {
		#header {
			min-width: 320px;

			> h1 span {
				display: none;
			}
		}
	}