/*
	Tactile by Pixelarity
	pixelarity.com | hello@pixelarity.com
	License: pixelarity.com/license
*/

/* Main */

	#main {
		> header {
			@include color-typography(accent4);
			@include padding(10em, 0);
			background-image: linear-gradient(rgba(#000, 0.3), rgba(#000, 0.3)), url('../../images/banner.jpg');
			background-attachment: fixed;
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
			background-attachment: fixed;
			position: relative;
			text-align: center;

			h2 {
				font-size: 2em;
			}

			p {
				color: inherit;
				letter-spacing: _font(spacing-wide);
				margin-top: 0;
			}

			@include breakpoint(xlarge) {
				@include padding(8em, 0);
			}

			@include breakpoint(large) {
				@include padding(8em, 3em);
				background-attachment: scroll;
			}

			@include breakpoint(medium) {
				@include padding(10em, 3em);
			}

			@include breakpoint(small) {
				@include padding(4.5em, 3em);

				h2 {
					font-size: 1.5em;
					margin: 0 0 (_size(element-margin) * 0.5) 0;
				}
			}
		}
	}