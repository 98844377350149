/*
	Tactile by Pixelarity
	pixelarity.com | hello@pixelarity.com
	License: pixelarity.com/license
*/

/* Footer */

	#footer {
		@include color(accent2);
		@include padding(5em, 0);

		a {
			color: inherit;
			text-decoration: none;

			&:hover {
				color: _palette(accent2, fg-bold);
			}
		}

		> .inner {
			@include vendor('display', 'flex');
			@include vendor('flex-wrap', 'wrap');
			margin: 0 auto;
			max-width: _size(inner);
			width: 100%;
		}

		.about {
			width: 50%;
			margin-bottom: _size(element-margin);
		}

		.contact-info {
			@include vendor('display', 'flex');
			@include vendor('flex-wrap', 'wrap');
			margin-bottom: _size(element-margin);
			padding-left: 4em;
			width: 50%;

			h4 {
				width: 100%;
			}

			ul {
				width: 50%;
			}
		}

		.copyright {
			color: _palette(fg-light);
			font-size: 0.9em;
			margin: 0 0 _size(element-margin) 0;
			padding: 0;
			text-align: center;

			li {
				border-left: solid _size(border-width) _palette(border);
				display: inline-block;
				list-style: none;
				margin-left: 1.5em;
				padding-left: 1.5em;

				&:first-child {
					border-left: 0;
					margin-left: 0;
					padding-left: 0;
				}
			}
		}

		@include breakpoint(large) {
			@include padding(4em, 2em);

			.contact-info {
				padding-left: 2em;
			}
		}

		@include breakpoint(medium) {
			@include padding(4em, 2em);

			.about {
				width: 100%;
			}

			.contact-info {
				padding-left: 0;
				width: 100%;
			}
		}

		@include breakpoint(small) {
			@include padding(3.5em, 2em);

			.about,
			.contact-info {
				margin-bottom: (_size(element-margin) * 0.5);
			}

			.copyright {
				li {
					display: block;
					border-left: 0;
					margin-left: 0;
					padding-left: 0;
				}
			}
		}

		@include breakpoint(xsmall) {
			@include padding(3em, 2em);

			.copyright {
				text-align: left;
			}

			.contact-info {
				ul {
					margin-bottom: 0;
					width: 100%;
				}
			}
		}
	}